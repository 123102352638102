<template>
  <div class="container">
    <div class="restaurant">
      <v-row class="">
        <v-col cols="12" class="top-panel">
          <div class="arrow-btn">
            <router-link v-if="user &&
              user.roles &&
              user.roles.length > 0 &&
              user.roles[0].employeeRole === 'Root'
              " to="/ProductList">
              <img src="../assets/img/arrowBack.svg" class="img-pluss-btn" alt="arrow-back" />
            </router-link>
            <router-link v-else to="/ProductList">
              <img src="../assets/img/arrowBack.svg" class="img-pluss-btn" alt="arrow-back" />
            </router-link>
          </div>
          <div class="vertical-line-1"></div>
        </v-col>
      </v-row>
      <v-row class="product-big-container">
        <v-col cols="12" class="title-container py-0">
          <h1 class="page-title">Новый продукт</h1>
        </v-col>
      </v-row>
      <v-row class="product-big-container">
        <v-col cols="12" class="title-container-tabs">
          <template>
            <div>
              <v-row>
                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title pb-0">Описание</h2>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Название</p>
                        </v-col>
                        <v-col cols="" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="nameRu" ref="nameRuTextarea"
                            :placeholder="isnameRUFocused ? '' : textareaPlaceholder
              " @focus="isnameRUFocused = true" @blur="isnameRUFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Название EN</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="nameEN" ref="nameENTextarea"
                            :placeholder="isnameENFocused ? '' : textareaPlaceholder
              " @focus="isnameENFocused = true" @blur="isnameENFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Описание</p>
                        </v-col>

                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="descriptionRU"
                            ref="descriptionRUTextarea" :placeholder="isdescriptionRUFocused ? '' : textareaPlaceholder
              " @focus="isdescriptionRUFocused = true"
                            @blur="isdescriptionRUFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Цена</p>
                        </v-col>
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0 mb-4">
                          <input type="number" class="col-description textarea-pt" v-model="price" ref="priceTextarea"
                            :placeholder="ispriceFocused ? '' : textareaPlaceholder
              " @focus="ispriceFocused = true" @blur="ispriceFocused = false" />
                        </v-col>
                      </div>
                    </v-col>


                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Производитель</p>
                        </v-col>

                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="manufacture" ref="manufactureTextarea"
                            :placeholder="ismanufactureFocused ? '' : textareaPlaceholder
              " @focus="ismanufactureFocused = true" @blur="ismanufactureFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>


                    <div class="horizontal-line"></div>


                  </div>
                </v-col>
                <!-- <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title">Фотография</h2>
                    </v-col>
                    <v-col cols="12" class="photo-description">
                      <p class="information-text-1">Фото продукта</p>
                      <span class="information-text-2">638рх* 592рх </span>
                      <p class="information-text-3">1mb</p>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="">
                      <div class="img-container-flex">
                        <div v-if="uploadedImageLogo" class="uploaded-image-container">
                          <img :src="uploadedImageLogo" alt="Uploaded Image" class="uploaded-image-logo" />
                        </div>
                        <input type="file" accept="image/*" id="img-input-1" @change="uploadImageLogo"
                          style="display: none" />
                        <div class="btn-photo">
                          <label for="img-input-1" class="custom-file-upload">
                            <img src="@/assets/img/input-logo.svg" alt="" />
                          </label>
                          <label v-if="uploadedImageLogo" for="img-input-1" class="custom-file-upload">
                            <img src="@/assets/img/photo-delete.svg" alt="" />
                          </label>
                        </div>
                      </div>
                    </v-col>
                  </div>
                </v-col> -->
                <v-col cols="8">
                  <div class="btn-save-container">
                    <button class="btn-save" @click="handleButtonClick">
                      Добавить
                    </button>
                  </div>
                </v-col>
              </v-row>
              <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                    Закрыть
                  </div>
                </template>
              </v-snackbar>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.page-title {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}

.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}

.btn-save-container {
  text-align: end;
}

.btn-save {
  border-radius: 8px;
  background: var(--03, #FFD500);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  color: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-photo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.photo-description {
  display: flex;
  align-items: center;
}

.information-text-3 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 30px;
}

.information-text-2 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 43px;
}

.information-text-1 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.input-select-width {
  width: 30%;
}

.product-big-container {
  margin-left: 39px;
  margin-right: 30px;
}

.col-content-energy {
  display: flex;
  align-items: flex-start;
}

.weight-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
}

.month-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background: #f2f2f680;
}

::v-deep .v-list-item__title {
  align-self: center;

  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

::v-deep .v-application .primary--text {
  color: #bcb648 !important;
  caret-color: #bcb648 !important;
}

::v-deep .v-select__selection--comma {
  margin: 7px 4px 7px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

::v-deep .v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--06, #fff);
}

::v-deep .v-text-field>.v-input__control>.v-input__slot:before,
.v-text-field>.v-input__control>.v-input__slot:after {
  bottom: -1px;
  content: none !important;
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.select-text {
  padding-left: 5px;
}

.select-container {
  margin: 0 0 0 auto;
  width: 63px;
}

.textarea-pt {
  padding-top: 19px;
}

.work-margin-bot {
  margin-bottom: 50px;
}

.col-title-work {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.special-border:focus {
  border-radius: 3px;
  border: 2px solid var(--03, black);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}

.special-border {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}

.time-end {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 146.667% */
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 146.667% */
}

.end-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.work-img-margin {
  margin-right: 21px;
}

.work-1 {
  text-align: end;
}

.dot-work {
  margin-left: 69px;
}

.work-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-container {
  margin-top: 17px;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .top-panel .container {
    width: 100%;
    padding: 0px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
  text-decoration: none;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.full-text-input-3 {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-2 {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input,
.full-text-input-2,
.full-text-input-3,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.full-text-input,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}

.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 227px;
}

.margin-logo-divider {
  margin-bottom: 48px;
}

.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-file-upload {
  cursor: pointer;
}

.img-container-flex {
  display: flex;
  justify-content: space-between;
}

.uploaded-image-logo {
  /* max-width: 392px; 
  max-height: 382px;  */
  max-width: 100%;
  /* Adjust this value as needed */
  max-height: 100%;
  /* Adjust this value as needed */
}

.uploaded-image-container {
  width: auto;
  height: auto;
  border-radius: 10px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  padding: 14px;
}

.work-margin-img {
  margin-bottom: 15px;
}

.work-img {
  width: 22px;
  height: 22px;
}

.col-img {
  flex-direction: column-reverse;
  display: flex;
}

.work-margin {
  padding-bottom: 15px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}

.tel-pointer {
  cursor: pointer;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}

.col-content {
  display: flex;
  align-items: center;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  min-height: 20px;
  /* Минимальная высота для textarea */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
}

@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "CreateProduct",
  directives: { mask },
  props: {
    requestData: {},
    responseData: {},
  },
  data() {
    return {
      selectedImage: null,

      message: "",
      isPlaceholderHidden: false,
      isminOrderFocused: false,
      iscommisionFromOrderFocused: false,
      isorderRestinAppFocused: false,
      isphoneFocused: false,
      isinstagramFocused: false,
      isnameRUFocused: false,
      isnameENFocused: false,
      ispriceFocused: false,
      ismanufactureFocused: false,

      isdescriptionRUFocused: false,
      isdescriptionENFocused: false,


      minOrder: "",
      uploadedImageLogo: "",
      commisionFromOrder: "",
      nameRu: "",
      nameEN: "",
      manufacture: "",
      price: null,
      descriptionRU: "",
      descriptionEN: "",
      bestBeforeMonths: "",
      orderRestinApp: "",

      newProduct: {},
      snackbar: false,
    };
  },
  methods: {
    uploadImageLogo(event) {
      let file = event.target.files[0];

      // Проверяем, изменилось ли изображение
      if (file !== this.selectedImage) {
        this.isImageChanged = true;
        this.selectedImage = file;

        if (file) {
          this.uploadedImageLogo = URL.createObjectURL(file);
          console.log(this.uploadedImageLogo);
          this.uploadImagesProduct = file;
        } else {
          this.uploadedImageLogo = null;
          this.uploadImagesProduct = null;
        }
      }
    },
    createNewProduct() {
      //   this.newEmp.employeeRole = this.getEnglishRoleTranslation(
      //
      //   );
      (this.newProduct.name = this.nameRu),
        (this.newProduct.manufacturer = this.manufacture),
        (this.newProduct.origin = this.originRU),
        (this.newProduct.description = this.descriptionRU),
        (this.newProduct.price = this.price),
        (this.newProduct.bestBeforeMonths = this.bestBeforeMonths),
        // (this.newEmp.mobilePhone = this.phone),
        (this.newProduct.localizations = [
          {
            locale: "en",
            name: this.nameEN,
            description: this.descriptionEN,
            energyValue: this.energyValueEN,
            manufacturer: this.manufacture,
            origin: this.originEN,
          },
        ]),
        this.$http
          .post("/api/Product", this.newProduct)
          .then(() => {
            this.newProduct = {
              name: "",
              manufacture: "",
              price: "",
              description: "",
              localizations: [
                {
                  locale: "",
                  name: "",
                  description: "",
                  energyValue: "",
                  manufacturer: "",
                  origin: "",
                },
              ],
              //   employeeRole: null,
            };

            this.snackbar = true;
            this.message = "Продукт успешно добавлен";
            setTimeout(() => {
              this.$router.push({ name: "ProductList" });
            }, 2000); // 2000 миллисекунд (2 секунды)
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
            this.message = "Ошибка добавления продукта";
          });
    },

    handleButtonClick() {
      this.createNewProduct();
    },
    handleInput() {
      this.bestBeforeMonths = this.bestBeforeMonths.replace(/[^0-9.,]/g, "");
    },
    pluralizeMonths(months) {
      const num = parseInt(months);
      if (isNaN(num)) return "";

      if (num === 1) return "месяц";
      if (num > 1 && num < 5) return "месяца";
      return "месяцев";
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
  },

  watch: {
    nameRu() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameRuTextarea");
      });
    },
    nameEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameENTextarea");
      });
    },
    manufacture() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("manufactureTextarea");
      });
    },
    descriptionRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionRUTextarea");
      });
    },
    descriptionEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionENTextarea");
      });
    },
    price() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("priceTextarea");
      });
    },
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },

    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style lang="scss">
@media (min-width: 1264px) {
  .container {
    //1185px дефолтное название если что то ломаться начнется на маленьких мониторах
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {

  //900 дефолтное название если что то ломаться начнется на маленьких мониторах
  .container {
    max-width: 100% !important;
  }
}
</style>
